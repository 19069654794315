.text-input-container {
	width: 100%;
	display: inline-block;
}

.text-input-container.half {
	width: 49%;
}

.text-input-container.half:nth-child(2n) {
	margin-left: 2%;
}

.text-input {
	width: 100%;
}
